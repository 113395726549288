import * as THREE from "three"
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js"
import { DRACOLoader } from "three/addons/loaders/DRACOLoader.js"
import { gsap } from "gsap"

const scene = new THREE.Scene()

const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
  pixelRatio: Math.min(window.devicePixelRatio, 2),
}

const canvas = document.querySelector("canvas.webglHH")

const camera = new THREE.PerspectiveCamera(
  75,
  window.innerWidth / window.innerHeight,
  0.1,
  1000
)

const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
  powerPreference: "high-performance",
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.render(scene, camera)

let particles2 = null

const loader = new GLTFLoader()
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath("/draco/")
loader.setDRACOLoader(dracoLoader)

// Particles morphing by Bruno Simon

loader.load("heroDraco.glb", (gltf) => {
  particles2 = {}
  particles2.index = 1

  const positions = gltf.scene.children.map(
    (child) => child.geometry.attributes.position
  )
  particles2.maxCount = 0
  for (const position of positions) {
    if (position.count > particles2.maxCount)
      particles2.maxCount = position.count
  }

  particles2.positions = []
  for (const position of positions) {
    const originalArray = position.array
    const newArray = new Float32Array(particles2.maxCount * 3)

    for (let i = 0; i < particles2.maxCount; i++) {
      const i3 = i * 3
      if (i3 < originalArray.length) {
        newArray[i3 + 0] = originalArray[i3 + 0]
        newArray[i3 + 1] = originalArray[i3 + 1]
        newArray[i3 + 2] = originalArray[i3 + 2]
      } else {
        const randomIndex = Math.floor(position.count * Math.random()) * 3

        newArray[i3 + 0] = originalArray[randomIndex + 0]
        newArray[i3 + 1] = originalArray[randomIndex + 1]
        newArray[i3 + 2] = originalArray[randomIndex + 2]
      }
    }
    particles2.positions.push(new THREE.Float32BufferAttribute(newArray, 3))
  }

  const sizesArray = new Float32Array(particles2.maxCount)
  for (let i = 0; i < particles2.maxCount; i++) sizesArray[i] = Math.random()
  particles2.geometry = new THREE.BufferGeometry()
  particles2.geometry.setAttribute(
    "position",
    particles2.positions[particles2.index]
  )
  particles2.geometry.setAttribute("aPositionTarget", particles2.positions[3])
  particles2.geometry.setAttribute(
    "aSize",
    new THREE.BufferAttribute(sizesArray, 1)
  )

  particles2.colorA = "#FF0800"
  particles2.colorB = "#7C0A02"

  particles2.material = new THREE.ShaderMaterial({
    vertexShader: `
    uniform vec2 uResolution;
uniform float uSize;
uniform float uProgress;
attribute vec3 aPositionTarget;
attribute float aSize;
uniform vec3 uColorA;
uniform vec3 uColorB;
uniform vec2 uMousePosition;

varying vec3 vColor;

//	Simplex 3D Noise 
//	by Ian McEwan, Ashima Arts
//
vec4 permute(vec4 x){ return mod(((x*34.0)+1.0)*x, 289.0); }
vec4 taylorInvSqrt(vec4 r){ return 1.79284291400159 - 0.85373472095314 * r; }

float simplexNoise3d(vec3 v)
{
    const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
    const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);

    // First corner
    vec3 i  = floor(v + dot(v, C.yyy) );
    vec3 x0 =   v - i + dot(i, C.xxx) ;

    // Other corners
    vec3 g = step(x0.yzx, x0.xyz);
    vec3 l = 1.0 - g;
    vec3 i1 = min( g.xyz, l.zxy );
    vec3 i2 = max( g.xyz, l.zxy );

    //  x0 = x0 - 0. + 0.0 * C 
    vec3 x1 = x0 - i1 + 1.0 * C.xxx;
    vec3 x2 = x0 - i2 + 2.0 * C.xxx;
    vec3 x3 = x0 - 1. + 3.0 * C.xxx;

    // Permutations
    i = mod(i, 289.0 ); 
    vec4 p = permute( permute( permute( i.z + vec4(0.0, i1.z, i2.z, 1.0 )) + i.y + vec4(0.0, i1.y, i2.y, 1.0 ))  + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));

    // Gradients
    // ( N*N points uniformly over a square, mapped onto an octahedron.)
    float n_ = 1.0/7.0; // N=7
    vec3  ns = n_ * D.wyz - D.xzx;

    vec4 j = p - 49.0 * floor(p * ns.z *ns.z);  //  mod(p,N*N)

    vec4 x_ = floor(j * ns.z);
    vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)

    vec4 x = x_ *ns.x + ns.yyyy;
    vec4 y = y_ *ns.x + ns.yyyy;
    vec4 h = 1.0 - abs(x) - abs(y);

    vec4 b0 = vec4( x.xy, y.xy );
    vec4 b1 = vec4( x.zw, y.zw );

    vec4 s0 = floor(b0)*2.0 + 1.0;
    vec4 s1 = floor(b1)*2.0 + 1.0;
    vec4 sh = -step(h, vec4(0.0));

    vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
    vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;

    vec3 p0 = vec3(a0.xy,h.x);
    vec3 p1 = vec3(a0.zw,h.y);
    vec3 p2 = vec3(a1.xy,h.z);
    vec3 p3 = vec3(a1.zw,h.w);

    // Normalise gradients
    vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
    p0 *= norm.x;
    p1 *= norm.y;
    p2 *= norm.z;
    p3 *= norm.w;

    // Mix final noise value
    vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
    m = m * m;
    return 42.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1), dot(p2,x2), dot(p3,x3) ) );
}

void main()
{
    float noiseOrigin = simplexNoise3d(position * 0.2);
    float noiseTarget = simplexNoise3d(aPositionTarget * 0.2);
    float noise = mix(noiseOrigin, noiseTarget, uProgress);
    noise = smoothstep(-1.0, 1.0, noise);

    float duration = 0.4;
    float delay = (1.0 - duration) * noise;
    float end = delay + duration;
    float progress = smoothstep(delay, end, uProgress);
    
    vec3 mixedPosition = mix(position, aPositionTarget, progress); 
    vec4 modelPosition = modelMatrix * vec4(mixedPosition, 1.0);
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectedPosition = projectionMatrix * viewPosition;
    gl_Position = projectedPosition;

    gl_PointSize = aSize * uSize * uResolution.y;
    gl_PointSize *= (1.0 / - viewPosition.z);

    vColor = mix(uColorA, uColorB, noise);
    float mouseOffset = length(uMousePosition - gl_Position.xy / uResolution.xy);
    vColor += vec3(mouseOffset * 0.25);
}
    `,
    fragmentShader: `
    varying vec3 vColor;
    void main()
{
    vec2 uv = gl_PointCoord;
    float distanceToCenter = length(uv -0.5);
    float alpha = 0.05 / distanceToCenter - 0.1;

    gl_FragColor = vec4(vColor, alpha);
    #include <tonemapping_fragment>
    
}
    `,
    uniforms: {
      uSize: new THREE.Uniform(0.16),
      uResolution: new THREE.Uniform(
        new THREE.Vector2(
          sizes.width * sizes.pixelRatio,
          sizes.height * sizes.pixelRatio
        )
      ),
      uProgress: new THREE.Uniform(0),
      uColorA: new THREE.Uniform(new THREE.Color(particles2.colorA)),
      uColorB: new THREE.Uniform(new THREE.Color(particles2.colorB)),
      uMousePosition: { value: new THREE.Vector2() },
    },
    blending: THREE.AdditiveBlending,
    depthWrite: false,
  })

  particles2.points = new THREE.Points(particles2.geometry, particles2.material)
  scene.add(particles2.points)

  particles2.morph = (index) => {
    particles2.geometry.attributes.position =
      particles2.positions[particles2.index]
    particles2.geometry.attributes.aPositionTarget = particles2.positions[index]

    gsap.fromTo(
      particles2.material.uniforms.uProgress,
      { value: 0 },
      { value: 1, duration: 3, ease: "linear" }
    )

    particles2.index = index
  }

  particles2.morph0 = () => {
    particles2.morph(0)
  }
  particles2.morph1 = () => {
    particles2.morph(1)
  }
  particles2.morph2 = () => {
    particles2.morph(2)
  }
  particles2.morph3 = () => {
    particles2.morph(3)
  }
  particles2.morph3 = () => {
    particles2.morph(4)
  }
})

window.addEventListener("resize", () => {
  sizes.width = window.innerWidth
  sizes.height = window.innerHeight
  sizes.pixelRatio = Math.min(window.devicePixelRatio, 2)

  particles2.material.uniforms.uResolution.value.set(
    sizes.width * sizes.pixelRatio,
    sizes.height * sizes.pixelRatio
  )

  camera.aspect = sizes.width / sizes.height
  camera.updateProjectionMatrix()

  renderer.setSize(sizes.width, sizes.height)
  renderer.setPixelRatio(sizes.pixelRatio)
})

let interactiveBg

const textureLoader = new THREE.TextureLoader()
textureLoader.load("./bg_bigv2S.jpg", function (texture1) {
  texture1.magFilter = THREE.LinearFilter
  texture1.minFilter = THREE.LinearFilter
  texture1.minFilter = THREE.LinearMipmapLinearFilter
  texture1.anisotropy = renderer.capabilities.getMaxAnisotropy()
  const interactivePlane = new THREE.PlaneGeometry(3, 3)
  const interactiveMat = new THREE.ShaderMaterial({
    uniforms: {
      tex1: { value: texture1 },
      tex2: { value: texture1 },
      tex3: { value: texture1 },
      tex4: { value: texture1 },
      cursor: { value: new THREE.Vector2() },
      resolution: {
        value: new THREE.Vector2(window.innerWidth, window.innerHeight),
      },
      time2: { value: 0.0 },
    },
    vertexShader: `
                varying vec2 vUv;
                void main() {
                    vUv = uv;
                    gl_Position = vec4(position, 1.0);
                }
            `,
    fragmentShader: `
                uniform sampler2D tex1;
                uniform sampler2D tex2;
                uniform sampler2D tex3;
                uniform sampler2D tex4;
                uniform vec2 cursor;
                uniform vec2 resolution;
                uniform float time2;

                varying vec2 vUv;

                void main() {
                  vec2 normalizedUV = vUv * 2.0 - 1.0;
                  float dist = length(normalizedUV - cursor);
                  float resolution = 0.4;
                  float distortion = smoothstep(resolution, 0.0, dist); 

                    vec2 distortedUV1 = vUv + (normalizedUV - cursor) * distortion * -0.3;
                    vec2 distortedUV2 = vUv + (normalizedUV - cursor) * distortion * 0.3;
                    vec2 distortedUV3 = vUv + (normalizedUV - cursor) * distortion * -0.2;
                    vec2 distortedUV4 = vUv + (normalizedUV - cursor) * distortion * 0.2;

                    vec4 color1 = texture2D(tex1, distortedUV1);
                    vec4 color2 = texture2D(tex2, distortedUV2);
                    vec4 color3 = texture2D(tex3, distortedUV3);
                    vec4 color4 = texture2D(tex4, distortedUV4);

                    // Blend the colors based on mouse position
                    float blendFactor = clamp(distortion * 2.0, 0.0, 1.0);
                    vec4 finalColor = mix(color1, color2, blendFactor);
                    finalColor = mix(finalColor, color3, blendFactor);
                    finalColor = mix(finalColor, color4, blendFactor);

                    gl_FragColor = finalColor;
                }
            `,
    blending: THREE.AdditiveBlending,
    transparent: true,
  })

  interactiveBg = new THREE.Mesh(interactivePlane, interactiveMat)
  scene.add(interactiveBg)
})

//-------------------------------------------------------------

const targetCameraPosition = new THREE.Vector3(0, 7, 0)
const smoothness = 0.02

const targetCursorPosition = new THREE.Vector2()
const cursorSmoothness = 0.02

const functionSequence = [
  {
    title: "IDEA.\nDZIEŁO.\nIMMERSJA.",
    subtitle: "",
    about: "",
    func: () => particles2.morph(1),
  },
  {
    title: "EMPIRIA\nGŁĘBI",
    subtitle:
      "Poznaj interaktywny świat trójwymiarowych aplikacji, który przenosi doświadczenie użytkownika na nowy poziom.",
    about: "",
    func: () => particles2.morph(2),
  },
  {
    title: "KREOWANIE\nPIKSELI",
    subtitle:
      "Każdy piksel ma znaczenie, a każda linia opowiada historię, gdzie rzeczywistość splata się z wyobraźnią.",
    about: "",
    func: () => particles2.morph(3),
  },
  {
    title: "APLIKACJE\nMOBILNE",
    subtitle:
      "Nowy wymiar mobilnych doznań w świetle technologii interaktywnego renderowania – odkryj go.",
    about: "",
    func: () => particles2.morph(0),
  },
  {
    title: "",
    subtitle: "",
    about:
      "Elementuz to studio interaktywne, wyspecjalizowane w projektowaniu aplikacji webowych i mobilnych, jak również grafiki komputerowej. Nasze podejście oparte jest na filozofii harmonii pomiędzy Twoją wizją a  naszym kodem. Wykorzystujemy najwydajniejsze narzędzia i frameworki, aby zapewnić naszym klientom wyjątkową jakość. Podziel się swoją koncepcją projektu, a my pokażemy Ci, jak technologia splata się z obrazem, tworząc niezrównaną immersję. Pamiętaj jednak, że każde dzieło jest odzwierciedleniem jego twórcy.",
    func: () => particles2.morph(4),
  },
]

let currentFunctionIndex = 0
let spinsPerTriggerMouse = 1
let spinsPerTriggerTouchpad = 1
let spinsCount = 0
let isFunctionExecuting = false

function triggerAnimation(index) {
  const { title, subtitle, about, func } = functionSequence[index]
  const animationTitle = document.getElementById("animationTitle")
  const animationSubtitle = document.getElementById("animationSubtitle")
  const animationAbout = document.getElementById("about")

  ;[animationTitle, animationSubtitle, animationAbout].forEach((element) => {
    element.style.transition = "opacity 1s ease"
    element.style.opacity = 0
  })

  setTimeout(() => {
    animationTitle.innerHTML = title
    animationTitle.style.transition = "opacity 3s ease"
    animationTitle.style.opacity = 1

    animationAbout.innerHTML = about
    animationAbout.style.transition = "opacity 3s ease"
    animationAbout.style.opacity = 1

    setTimeout(() => {
      animationSubtitle.innerHTML = subtitle
      animationSubtitle.style.transition = "opacity 3s ease"
      animationSubtitle.style.opacity = 1

      func()
    }, 500)
  }, 1000)
}

function onDocumentMouseWheelMorph(event) {
  const deltaY = event.deltaY

  const spinsPerTrigger =
    event.deltaMode === 0 ? spinsPerTriggerMouse : spinsPerTriggerTouchpad

  spinsCount += deltaY < 0 ? -1 : 1

  if (!isFunctionExecuting && Math.abs(spinsCount) >= spinsPerTrigger) {
    isFunctionExecuting = true
    const newIndex = currentFunctionIndex + Math.sign(spinsCount)
    currentFunctionIndex =
      (newIndex < 0 ? functionSequence.length - 1 : newIndex) %
      functionSequence.length

    triggerAnimation(currentFunctionIndex)
    spinsCount = 0

    setTimeout(() => {
      isFunctionExecuting = false
    }, 2000)
  }
}

function triggerMorphAnimation() {
  if (!isFunctionExecuting) {
    isFunctionExecuting = true
    const newIndex = currentFunctionIndex + Math.sign(spinsCount)
    currentFunctionIndex =
      (newIndex < 0 ? functionSequence.length - 1 : newIndex) %
      functionSequence.length
    triggerAnimation(currentFunctionIndex)
    spinsCount = 0
    setTimeout(() => {
      isFunctionExecuting = false
    }, 2000)
  }
}

function updateCameraPosition() {
  camera.position.lerp(targetCameraPosition, smoothness)
  camera.lookAt(0, 0, 0)
}

function updateMouseAndCursorPositions() {
  if (particles2 && particles2.material) {
    interactiveBg.material.uniforms.cursor.value.lerp(
      targetCursorPosition,
      cursorSmoothness
    )
  }
}

function onDocumentMouseMove(event) {
  const mouseX = (event.clientX / window.innerWidth) * 2 - 1
  const mouseY = -(event.clientY / window.innerHeight) * 2 + 1
  targetCursorPosition.set(mouseX, mouseY)

  particles2.material.uniforms.uMousePosition.value = targetCursorPosition
}

function onDocumentMouseWheel(event) {
  const deltaY = event.deltaY
  targetCameraPosition.y += deltaY * 0.02
  targetCameraPosition.y = Math.max(targetCameraPosition.y, 7)
  targetCameraPosition.y = Math.min(targetCameraPosition.y, 7)
}

function onDocumentTouchStart(event) {
  const touch = event.touches[0]
  const rect = canvas.getBoundingClientRect()
  const touchX = ((touch.clientX - rect.left) / rect.width) * 2 - 1
  const touchY = -((touch.clientY - rect.top) / rect.height) * 2 + 1
  targetCursorPosition.set(touchX, touchY)
}

function onDocumentTouchMove(event) {
  const touch = event.touches[0]
  const rect = canvas.getBoundingClientRect()
  const touchX = ((touch.clientX - rect.left) / rect.width) * 2 - 1
  const touchY = -((touch.clientY - rect.top) / rect.height) * 2 + 1
  targetCursorPosition.lerp(new THREE.Vector2(touchX, touchY), 1.0)
  spinsCount += 1
}

function onDocumentTouchEnd(event) {
  triggerMorphAnimation()
}

function onDocumentPointerMove(event) {
  const pointerX = (event.clientX / window.innerWidth) * 2 - 1
  const pointerY = -(event.clientY / window.innerHeight) * 2 + 1
  targetCursorPosition.set(pointerX, pointerY)
}

function animate() {
  requestAnimationFrame(animate)
  updateCameraPosition()
  updateMouseAndCursorPositions()
  renderer.render(scene, camera)
}

triggerAnimation(0)

const eventListeners = {
  wheel: [onDocumentMouseWheelMorph, onDocumentMouseWheel],
  mousemove: [onDocumentMouseMove],
  touchstart: [onDocumentTouchStart],
  touchmove: [onDocumentTouchMove],
  touchend: [onDocumentTouchEnd],
  pointermove: [onDocumentPointerMove],
}

for (const eventType in eventListeners) {
  eventListeners[eventType].forEach((listener) => {
    document.addEventListener(eventType, listener, false)
  })
}

animate()
